var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "outer" }, [
    _c(
      "header",
      [
        _c("div"),
        _c(
          "el-button",
          { attrs: { size: "mini" }, on: { click: _vm.addUser } },
          [_vm._v(" New ")]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "inner" }, [
      _c(
        "ul",
        _vm._l(_vm.users, function (user) {
          return _c(
            "li",
            {
              key: user.id,
              on: {
                click: function ($event) {
                  return _vm.navigateToUser(user)
                },
              },
            },
            [
              _c("span", [_vm._v(_vm._s(user.name))]),
              _c("svgicon", {
                staticClass: "triangle",
                attrs: { name: "triangle" },
              }),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }